import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { baseUrl } from './connections';
// export const baseUrl = 'https://layka-api.we-deliver.net';
// export const baseUrl = 'http://localhost:3003';
//export const baseUrl = 'http://localhost:3003';

//export const baseUrl = 'https://npaa-api.chainapptech.net';
interface LoginDto {
	email: string;
	password: string;
}

function handelError(error: any) {
	return { status: error?.response?.status, data: error?.response?.data };
}
function handleSuccess(response: any) {
	const data = !!response.data ? response.data : response.data;
	const responseData = parseJson(data);
	return { status: response.status, data: responseData };
}
const parseJson = (str: any) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
export async function whoAmI() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/whoami`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function login(user: LoginDto) {
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/signin`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function signUp(user: any) {
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/signup`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateHelpPage(page: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/help/page/update`,
		data: page,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getDefaultRole() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/settings/defaultRole`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllRoles() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/settings/roles`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateUserRole(id: string, roleId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/settings/user/role/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { id, roleId },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateSettings(id: string, value: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/settings/update/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { value },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllProducts(type: string) {
	const token = localStorage.getItem('accessToken');
	console.log(`Fetch data for: ${type}`);
	let url = `${baseUrl}/product/all?urlSlug=${type}`;
	const options: AxiosRequestConfig = {
		method: 'GET',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getFeatured() {
	const token = localStorage.getItem('accessToken');
	let url = `${baseUrl}/product/featured`;
	const options: AxiosRequestConfig = {
		method: 'GET',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCategoryDescription(type: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/category/desc?urlSlug=${type}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductInfo(o1: string, o2: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/by?o1=${o1}&o2=${o2}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllSymbols() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/symbols/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSymbolsForProduct(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-symbols?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getFontsForProduct(id: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-fonts?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDrafts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/drafts`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDraftsById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/draft/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteDraftsById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/product/draft/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function createOrder(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/order/create`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createCustomerOrder(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/customer/order/create`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductByOptions(optionIds: any[] = []) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/by?${optionIds.map((option, index) => `o${index + 1}=${option}`).join('&')}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getProductById(id: string) {
	const token = await localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/by?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createDraft(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/draft`,
		data: data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const getImage = (imageId: string) => `${baseUrl}/product/file/${imageId}`;

export async function createCountries(list: any[]) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: list,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCountries() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAllCategories() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/category/allCategories`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getCustomerByUserId() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/customers/getCustomerByUserId`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function editCustomer(id: string, data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function deleteCustomer(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changePassword(payload: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/change-password`,
		data: payload,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function uploadFile(data: any | { file: File; selectable: string }) {
	const token = localStorage.getItem('accessToken');
	const formData = new FormData();
	formData.append('file', data.file);
	formData.append('selectable', data.selectable);
	console.log('form data ', formData);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/file/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function updateUser(payload: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/update-user`,
		data: payload,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getMyOrders() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/order/my`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getOrderByOrderId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/order/by/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getOrderItemsByOrderId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/order/items/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMyAddresses() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
// export async function getAddressById(id: any) {
// 	const token = localStorage.getItem('accessToken');
// 	const options: AxiosRequestConfig = {
// 		method: 'GET',
// 		url: `${baseUrl}/address/${id}`,
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	};
// 	return axios(options)
// 		.then((response) => handleSuccess(response))
// 		.catch((error) => handelError(error));
// }
export async function getMyAddress() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAddressById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getRegions() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/regions`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMunicipalities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/municipalities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/cities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function editAddress(id: any, addressInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/address/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: addressInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function createAddress(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/profile/address/insert`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAvailableShippingMethods(ids: any[]) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/available-shippings`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { products: ids },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAvailablePayments(ids: any[]) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/available-payments`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: { products: ids },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changeDefaultShippingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_shipping_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function changeDefaultBillingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_billing_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function setDefaultAddress(b: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/profile/address/change-default`,
		data: b,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options).then((response) => handleSuccess(response));
}
export async function getAllFonts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/fonts/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getAllowedDesigns(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/allowed-designs?productId=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getSingleStaticPages() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/ssp/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getProductDesign(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/product-design/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getUserShippingAddress() {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/user/profile/address/shipping/default`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMenuCategories() {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'GET',
		url: `${baseUrl}/product/categories/menu`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function deleteAddress(id: string) {
	const token = localStorage.getItem('accessToken');
	const options = {
		method: 'DELETE',
		url: `${baseUrl}/user/profile/address/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
